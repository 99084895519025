import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { Card } from '../../../components/Card';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "accessibility",
      "style": {
        "position": "relative"
      }
    }}>{`Accessibility`}</h1>
    <p>{`Web accessibility is gaining more and more space in the context of software development. Given the
collaborative nature of the web and its importance in the process of facilitating communication, we must
ensure access to information and `}<strong parentName="p">{`provide the same experience to all users`}</strong>{`, regardless of their physical
and cognitive abilities or the platform and technologies used.`}</p>
    <blockquote cite='World Health Organization'>
  <p>
    Disability is not just a health problem. It is a complex phenomenon, reflecting the interaction between the
    characteristics of a person's body and the characteristics of the society in which they live.
  </p>
    </blockquote>
    <h2 {...{
      "id": "not-convinced-yet",
      "style": {
        "position": "relative"
      }
    }}>{`Not convinced yet?`}</h2>
    <p>{`According to data from the `}<a parentName="p" {...{
        "href": "https://www.who.int/disabilities/world_report/2011/report.pdf"
      }}>{`World Disability Report 2011`}</a>{` `}<strong parentName="p">{`one billion people worldwide live with some form of disability`}</strong>{` (representing 10% of the world population). In the Brazilian scenario, access to information is a right of every citizen guaranteed by the Federal Constitution. Besides that, the last `}<a parentName="p" {...{
        "href": "https://censo2010.ibge.gov.br/resultados/resumo.html"
      }}>{`Census`}</a>{` presents data indicating that `}<strong parentName="p">{`45.6 million people`}</strong>{` (23.9% of the total population) have some kind of disability.`}</p>
    <Card icon='heartOutline' mdxType="Card">
  Our goal is to build inclusive digital products to enhance the experience of all users. Accessibility means making
  people independent and ensuring they can complete their tasks in a similar effort and time as someone that does not
  have a disability.
    </Card>
    <h2 {...{
      "id": "accessible-components",
      "style": {
        "position": "relative"
      }
    }}>{`Accessible components`}</h2>
    <p>{`Bold components were built following the specifications of WCAG, conforming to AA level. Bold design
system enables accessible development by providing semantically correct components, each with an
appropriate ARIA markup so that they can be correctly identified by assistive technologies. However, it is
important to keep in mind that the design system is only the foundation for the development of affordable
applications. We recommend that you review and test your applications to ensure they conform to
`}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/WCAG20/#conformance"
      }}>{`WCAG standards at the AA level.`}</a>{`.`}</p>
    <h2 {...{
      "id": "how-were-the-accessibility-tests-carried-out",
      "style": {
        "position": "relative"
      }
    }}>{`How were the accessibility tests carried out?`}</h2>
    <p>{`The components and principles that make up Bold design were tested to ensure that they were suitable for accessibility. The process involved two stages of testing, the first with automated tools and the second with users.`}</p>
    <p><strong parentName="p">{`Testing with automated tools:`}</strong>{` we verified the contrasts and suitability for version 2.1 of the WCAG, suitability for e-mag, and automated testing tools (post-development): Google's Lighthouse and Deque's Axe.`}</p>
    <p><strong parentName="p">{`User testing:`}</strong>{` the efficiency, effectiveness, and satisfaction of bold users were tested through a usability test with a specific audience:`}</p>
    <ul>
      <li parentName="ul">{`1 user with visual impairment (blind);`}</li>
      <li parentName="ul">{`4 users with visual limitations (low vision, color blindness);`}</li>
      <li parentName="ul">{`2 users with temporary motor limitations (keyboard use only).`}</li>
    </ul>
    <p>{`Both the testing with tools and with users provided data used to improve the components and solutions implemented in BOLD. To see the complete study, go to: `}<a parentName="p" {...{
        "href": "https://repositorio.ufsc.br/handle/123456789/197794"
      }}>{`https://repositorio.ufsc.br/handle/123456789/197794`}</a></p>
    <h2 {...{
      "id": "best-practices",
      "style": {
        "position": "relative"
      }
    }}>{`Best practices`}</h2>
    <h3 {...{
      "id": "color",
      "style": {
        "position": "relative"
      }
    }}>{`Color`}</h3>
    <ul>
      <li parentName="ul">{`Color should not be used as the only visual means of conveying information, indicating an action, prompting a
response, or distinguishing a visual element.`}</li>
      <li parentName="ul">{`Limit the use of reds and greens, because it is easily confused with the more common type of color blindness.`}</li>
      <li parentName="ul">{`Plugin `}<a parentName="li" {...{
          "href": "https://www.getstark.co/"
        }}>{`Stark`}</a>{` for Sketch, make possible to see how the mock-ups are seen by different
types of color blindness.`}</li>
    </ul>
    <p><img parentName="p" {...{
        "src": "/image/accessibility-color.png",
        "alt": "Do/Don`t: Do: Use icons to indicate error states. Don`t: Use only color to convey information"
      }}></img></p>
    <h2 {...{
      "id": "contrast",
      "style": {
        "position": "relative"
      }
    }}>{`Contrast`}</h2>
    <p>{`In general, text and images must meet an optimal 4.5: 1 minimum contrast (for fonts equal to or less than 14pt), except for:`}</p>
    <ul>
      <li parentName="ul">{`Large texts (18.6px or 14pt) should maintain the contrast of at least 3: 1.`}</li>
      <li parentName="ul">{`Decorative text /image and disabled states don’t have contrast requirements.`}</li>
      <li parentName="ul">{`Logo or text that makes up a mark need not be within the ratio of contrast.`}</li>
    </ul>
    <p><img parentName="p" {...{
        "src": "/image/accessibility-contrast.png",
        "alt": "Do/Don't: Contrast test on tags and buttons"
      }}></img></p>
    <h3 {...{
      "id": "focus",
      "style": {
        "position": "relative"
      }
    }}>{`Focus`}</h3>
    <p>{`The component focused by the keyboard must be clearly marked, and the selection area must be clickable. By default,
links and form elements already display the highlighted border when they receive focus from the keyboard. This border
can be modified via CSS to enhance highlighting, but should `}<strong parentName="p">{`not`}</strong>{` be removed.`}</p>
    <p>{`We use and recommend that the minimum edge thickness should be 2 px.`}</p>
    <p><img parentName="p" {...{
        "src": "/image/accessibility-focus.png",
        "alt": "Focus on button and checkbox"
      }}></img></p>
    <h2 {...{
      "id": "assistive-technologies",
      "style": {
        "position": "relative"
      }
    }}>{`Assistive technologies`}</h2>
    <p>{`When people start to develop with accessibility in mind, it’s a common error add focus to every element on the page,
including text and titles. This difficult the navigation of users who can see and can hinder who depends on a screen reader,
because these technologies already provide focus to these elements.`}</p>
    <p>{`Titles, text blocks, and disabled fields should `}<strong parentName="p">{`not`}</strong>{` receive focus.`}</p>
    <p><img parentName="p" {...{
        "src": "/image/accessibility-assistivetech.png",
        "alt": "Disabled field and button"
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      